export default class Collapsable {
    constructor() {
        this.$collapsable = $('.Collapsable');
        this.title = '.Collapsable-title';
        this.content = '.Collapsable-content';
        this.setEventListeners();
    }

    setEventListeners() {
        $(document).on('click', '.Collapsable-title', this.onCollapsableTitleClick.bind(this));
    }

    //Functions
    openCollapsable($target) {
        if ($target.hasClass('Collapsable-open')) {
            $target
                .removeClass('Collapsable-open')
                .addClass('Collapsable-close')
                .find('> .Collapsable-content')
                .slideUp();
        } else {
            $target
                .removeClass('Collapsable-close')
                .addClass('Collapsable-open')
                .find('> .Collapsable-content')
                .slideDown();
        }
    }

    //Event Handlers
    onCollapsableTitleClick(event) {
        const $target = $(event.target).parent(this.$collapsable);
        this.openCollapsable($target);
    }
}
