import jqueryValidation from 'jquery-validation';
import jqueryValidationUnobstusive from 'jquery-validation-unobtrusive';

function getQueryValue(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default class Forms {
    constructor() {
        $('.Form').each(this.onEachForm.bind(this));

        this.$container = $('html,body');
        this.setEventListeners();
    }

    setEventListeners() {
        $(document).on('submit', '.Form--isAjax form', this.onAjaxFormSubmit.bind(this));
    }

    //functions

    replaceTextVars(input, $comp) {
        return input
            .replace('{formName}', $comp.data('formname'))
            .replace('{siteName}', $comp.data('sitename'));
    }

    onEachForm(i, elm) {
        var m = getQueryValue('message');
        if (m !== '' && m !== null && typeof m != 'undefined') {
            var $comp = $(elm);
            var $form = $comp.find('form');

            var successMessage = this.replaceTextVars($comp.data('successmessage'), $comp);
            var errorMessage = this.replaceTextVars($comp.data('errormessage'), $comp);

            if (m === 'success') {
                $form.html(successMessage);
            } else {
                $form.html(errorMessage);
            }
        }
    }

    onAjaxFormSubmit(event) {
        event.preventDefault();

        var that = this;
        var $container = that.$container;

        var response = grecaptcha.getResponse();

        var $form = $(event.currentTarget);
        var $comp = $form.closest('.Form');

        if (response.length === 0) {
            //First check if we have errors and we need to append a new error or create an error container
            var $validValidationSummary = $comp.find('.validation-summary-valid');

            if ($validValidationSummary.length > 0) {
                $validValidationSummary
                    .removeClass('validation-summary-valid')
                    .addClass('validation-summary-errors');
            }

            var $formValidContainer = $comp.find('.validation-summary-errors > ul');
            if ($formValidContainer.length > 0) {
                if ($formValidContainer.find('li.recaptcha-message').length === 0) {
                    if ($form.valid()) {
                        $formValidContainer.html(
                            '<li class="recaptcha-message">Invalid captcha!</li>',
                        );
                    } else {
                        $formValidContainer.append(
                            '<li class="recaptcha-message">Invalid captcha!</li>',
                        );
                    }
                }
            }

            //Set span error message
            var $formRecapthhaMessageError = $comp.find(
                '.js-recaptcha-message .field-validation-error',
            );
            if ($formRecapthhaMessageError.length === 0) {
                $('.js-recaptcha-message').append(
                    '<span class="field-validation-error"><span class="">Invalid captcha</span></span>',
                );
            }
        } else {
            var successMessage = this.replaceTextVars($comp.data('successmessage'), $comp);
            var errorMessage = this.replaceTextVars($comp.data('errormessage'), $comp);

            $.ajax({
                url: $form.attr('action'),
                method: 'POST',
                data: $form.serialize(),
            })
                .done(function(data) {
                    that.scrollTopForm($container, $form);
                    successMessage = '<div class="form-success">' + successMessage + '</div>';
                    $form.html(successMessage);
                })
                .fail(function() {
                    that.scrollTopForm($container, $form);
                    $form.html(errorMessage);
                });
        }
    }

    scrollTopForm($container, $form) {
        var scroll = $form.offset().top - 210;
        $container.animate({ scrollTop: scroll, scrollLeft: 0 }, 300);
    }
}
