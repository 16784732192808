export default class Partners {
  constructor() {
    this.setEventListeners();
  }

  setEventListeners() {
    $('body').on('click', '.js-items .js-pagination a', this.getItems.bind(this));
    $('body').on('click', '.js-items .js-viewmodeItem', this.getItems.bind(this));
    $('body').on('click', '.js-items .js-filterItem', this.getItems.bind(this));
    $('body').on('click', '.js-filter', this.toggleFilters.bind(this));
  }

  toggleFilters(e) {
    const $target = e.currentTarget;
    $($target)
      .closest('.Filter')
      .toggleClass('toggleFilters');

    if (!$('.js-filterItem').hasClass('active')) {
      $('.js-noFilters').addClass('active');
    }
  }

  getItems(e) {
    e.preventDefault();
    var $pagination = $('.js-items .js-pagination');
    var $filter = $('.js-items .js-filter');
    var $gridview = $('.js-items .js-viewmode');
    var $itemgridview = $('.js-items .js-itemgridview');

    var href = $(e.target).attr('href');

    $.ajax({
      url: href,
      context: document.body,
      beforeSend: function() {},
      success: function(data) {},
    }).done(function(data) {
      var $html = $($.parseHTML(data));
      var $dItemGridView = $html.find('.js-itemgridview');
      var $dFilter = $html.find('.js-filter');
      var $dGridView = $html.find('.js-viewmode');
      var $dPagination = $html.find('.js-pagination');
      $pagination.replaceWith($dPagination);
      $filter.replaceWith($dFilter);
      $gridview.replaceWith($dGridView);
      $itemgridview.replaceWith($dItemGridView);
    });
  }
}
