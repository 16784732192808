export default class Hamburger {
    constructor() {
        this.$body = $('body');
        this.$hamburger = $('.Hamburger');
        this.activeClass = 'toggle-menu';
        this.setEventListeners();
    }

    setEventListeners() {
        $(this.$hamburger).on('click', this.toggleMenu.bind(this));
    }

    toggleMenu(event) {
        if (this.$body.hasClass(this.activeClass)) {
            this.removeMenuClass();
        } else {
            this.addMenuClass();
        }
    }

    removeMenuClass(event) {
        this.$body.removeClass(this.activeClass);
    }

    addMenuClass(event) {
        this.$body.addClass(this.activeClass);
    }
}
