import './../style/styles.less';

import 'jquery';
import 'slick-carousel';

import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';

//Import components
import Menu from 'Menu';
import Hamburger from 'Hamburger';
import Collapsable from 'Collapsable';
import Faq from 'Faq';
import FilteredPage from 'FilteredPage';
import Partners from 'Partners';
import Forms from 'Forms';

//Instantiate components
var menu = new Menu();
var collapsable = new Collapsable();
var faq = new Faq();
var hamburger = new Hamburger();
var partners = new Partners();
var forms = new Forms();

$(function() {
    if (parseInt($(window).scrollTop()) > 100) {
        $('body').addClass('HeaderIsActive');
    }

    $('.js-activateModal').on('click', function() {
        $('.Modal, body').addClass('Modal-isActive');
    });

    $('.Modal-close').on('click', function() {
        $('.Modal, body').removeClass('Modal-isActive');
    });

    $(window).scroll(function() {
        var ScrollTop = parseInt($(window).scrollTop());

        if (ScrollTop > 100) {
            $('body').addClass('HeaderIsActive');
        } else {
            $('body').removeClass('HeaderIsActive');
        }
    });
});
