export default class Menu {
    constructor() {
        this.setEventListeners();
    }

    setEventListeners() {
        $(document)
            .on('click', '.js-Menu--subMenu--Back', this.onMenuBackClick.bind(this))
            .on('click', '.Menu-item--hasChildren .Menu-link--level0', this.onLevelOneClick.bind(this))
            .on('click', '.js-MenuToggle, .l-overlay', this.onHeaderNavClick.bind(this));
    }

    // Event Handlers
    onHeaderNavClick(event) {
        $('body').toggleClass('js-MenuToggle--show');
        $('.Menu, .Menu--subMenu, .Menu-item--hasChildren').removeClass('ActiveSubMenu');
    }

    onLevelOneClick(event) {
        var $target = $(event.target).closest('.Menu-link--level0');
        var $window = $(window)
        var width = $window.width();
        if (width < 1120) {
            event.preventDefault();
            $('.Menu-item--hasChildren').find('.Menu--subMenu').removeClass('ActiveSubMenu');
            $('.Menu-item--hasChildren').removeClass('ActiveSubMenu');
            $('.Menu').addClass('ActiveSubMenu');
            $target.parent('.Menu-item--hasChildren').addClass('ActiveSubMenu');
            $target.parent('.Menu-item').find('.Menu--subMenu').addClass('ActiveSubMenu');
        }
    }

    onMenuBackClick(event) {
        $('.Menu-item--hasChildren, .Menu--subMenu, .Menu').removeClass('ActiveSubMenu');
    }
}

// MenuItem has children to sub
var $menuItems = $(".Menu-item.Menu-item--hasChildren");
if ($menuItems.length > 0) {
    $menuItems.each(function () {
        var menuItemLink = $(this).find('a').attr('href');
        var menuItemText = $(this).find('.Menu-span--level0').text();
        $(this).closest(".Menu-item").find(".js-Menu--subMenu--Back").after('<li class="Menu-item"><a href="' + menuItemLink + '" class="Menu-item-Ancestor">' + menuItemText + '</a></li>' );
    })
}