export default class Faq {
    constructor() {
        this.$faqNavigationTitle = $('.Faq-navigation-title');
        this.content = '.Collapsable-content';
        this.active = 'Active';
        this.setEventListeners();
    }

    setEventListeners() {
        $(document).on('click', '.Faq-navigation-title', this.onTitleClick.bind(this));
    }

    //Event Handlers
    onTitleClick(event) {
        const $target = $(event.target);

        if (!$target.hasClass(this.active)) {
            this.$faqNavigationTitle.removeClass(this.active);
            $target.addClass(this.active);
            var attribute = $target.attr('faq');
            console.log(attribute);

            $('.FaqGroup').removeClass(this.active);
            $('.FaqGroup[faq="' + attribute + '"]').addClass(this.active);
        }
    }
}
